import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import RichText from '~components/RichText';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || 'inherit',
    position: 'relative'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '0px',
    borderRadius: '20px',
    marginTop: '110px',
    marginBottom: '72px',
    paddingTop: '71px',
    paddingBottom: '93px',
    backgroundColor: props => props.content_area_color || 'inherit',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '48px',
      paddingBottom: '24px',
      paddingLeft: '15px',
      paddingRight: '15px'
    }
  },
  avatar: {
    width: '137px',
    borderRadius: '999px',
    marginBottom: '33px'
  },
  title: {
    color: props => props.text_color,
    lineHeight: '116%',
    fontSize: '35px',
    fontWeight: '500',
    fontFamily: 'Inter',
    marginBottom: '24px',
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  description: {
    maxWidth: '780px',
    '& > *': {
      fontSize: '20px',
      fontFamily: 'Inter',
      lineHeight: '170%',
      marginBlockStart: 0,
      marginBottom: '34px',
      textAlign: 'center',
      '& > a': {
        fontWeight: '600',
        color: props => props.text_color,
        textDecoration: 'underline'
      },
      color: props => props.text_color,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        marginBottom: '27px'
      }
    },
    '& > *:last-child': {
      marginBottom: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  }
}));

const CourseAuthor = ({
  title,
  description,
  avatar,
  background_color,
  text_color,
  content_area_color,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color,
    text_color,
    content_area_color
  });

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <img src={avatar?.url} alt={avatar?.alt || title} className={classes.avatar} />
          <h2 className={classes.title}>{title}</h2>
          <RichText
            html={description?.html}
            verticalSpacing={0}
            externalClassName={classes.description}
          />
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CourseAuthor.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({ html: PropTypes.string }).isRequired,
  avatar: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  text_color: PropTypes.string,
  background_color: PropTypes.string,
  content_area_color: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CourseAuthor.defaultProps = {
  content_area_color: 'transparent',
  background_color: '#FFFFFF',
  text_color: '#000000',
  bottom_shape_divider: null
};

export default CourseAuthor;
